.facet-header-wrapper {
  position: relative;
  border-bottom: 1px solid #dee2e6;
  padding: 10px 0;
  margin-bottom: 10px;
}
.facet-header-wrapper h4 {
  text-transform: uppercase;
  font-size: 1.1rem;
  margin: 0 1rem 0 0;
}
.facet-header {
  display: inline-block;
  cursor: pointer;
}
.facet-header:hover {
  opacity: 0.8;
}
.facet-header--primary {
  max-width: 80%;
}
.facet-header--secondary {
  max-width: 20%;
  position: absolute;
  right: 0;
  bottom: 10px;
}
.checkbox-label {
  font-weight: normal;
  cursor: pointer;
  display: inline-block;
  width: 100%;
  line-height: 2rem;
  color: var(--primary);
  position: relative;
  z-index: 1;
}
.checkbox-label input {
  display: none;
}
.checkbox-label input + .facet-check {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -5px;
  right: -5px;
  background-color: transparent;
  z-index: -1;
  transition: background-color 0.2s ease-out;
}
.checkbox-label input:checked + .facet-check {
  background-color: #e1e9f0;
}
.facet-name {
  display: inline-block;
  max-width: 80%;
}
.facet-name:hover, .facet-name:focus {
  text-decoration: underline;
}
.facet-count {
  float: right;
  color: var(--grayish);
}


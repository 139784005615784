#originsMap {
    height: 750px;
    width: 100%;
    margin: 0 auto;
    z-index: 77;
}


.map-hint-icon {
    background-color: white;
    font-size: 20px;
    min-width: 8em;
    padding: 5px;
    display: inline-table;
    border: 1px solid black;
}


.selected-filter {
    background-color: grey !important;
}

/* Global custom properties */
:root {
  /* colors */
  --primary: #2967ab;
  --grayish: #6a7a89;
  --hilight: #c4f5f5;
}


html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll; /* quick fix to scrollbar bumbing content */
}

html, body {
  height: 100%;
}

/* prevent focus ring when using mouse (but not when using keyboard) */
body.using-mouse :focus {
  outline: none;
}

.App {
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 1.6rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1.3rem;
}
h4 {
  font-size: 1.2rem;
}
h5 {
  font-size: 1.1rem;
}

@media only screen and (max-width: 576px) {
  h1 { font-size: 1.3rem; }
  h2 { font-size: 1.25rem; }
  h3 { font-size: 1.2rem; }
  h4 { font-size: 1.15rem; }
  h5 { font-size: 1.1rem; }
}

a {
  color: var(--primary);
}

button:hover {
  text-decoration: underline;
}


em, .highlight {
  padding: 1px 3px;
  border-radius: 1px;
  background: var(--hilight);
  font-style: normal
}

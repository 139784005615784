.site-name {
    font-size: 2.2rem;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    font-weight: 200;
    text-decoration: none;
    color: #333;
  }
  
  .link-to-front {
    text-decoration: none;
  }


/*@import url('https://fonts.googleapis.com/css?family=Lobster');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');*/

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

:root {
  --sk-size: 40px;
  --sk-color: #333;
}


@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% { 
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.background__colored {
  background-color:blue;
  text-align:center
}
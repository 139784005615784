
.search-form {
  display: none;
}

.search-form-wrapper {
  margin: 3rem auto 5rem;
}
.form-type-select-area {
  text-align: center;
  margin-bottom: 1rem;
}
.form-type-select {
  background: none;
  margin: 0;
  padding: 0.5rem;
  border: none;
  cursor: pointer;
  color: var(--primary);
  font-size: 0.9rem;
}
.form-type-select--active {
  color: black;
}
.search-header {
  margin-bottom: '1rem';
}
.search-form {
  text-align: center;
}
.basic-search-term {
  background: white;
  width: 250px;
  max-width: 75%;
}
.basic-search-term fieldset {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.search-form .submit-basic-search {
  min-width: initial;
  height: 56px;
  margin-left: -1px;
  background-color: var(--primary);
  color: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.search-form .submit-basic-search:hover,
.search-form .submit-basic-search:focus {
  background-color: #569fdd;
}
.proximity-search-input {
  background: white;
  width: 150px;
}
.search-input--left fieldset {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.search-input--center fieldset {
  border-radius: 0;
}

/* active facets */
.facet-active-wrapper {
  margin-bottom: 0.5rem;
}
.facet-chip {
  margin: 0 5px 5px 0;
}

/* Result choosers */
.result-chooser-area {
  color: var(--grayish);
  margin: 0 0 10px;
}
.result-chooser-area:after {
  display: block;
  content: "";
  clear: both;
}
.result-chooser-wrapper {
  float: left;
  margin: 0 10px;
}
.result-chooser-wrapper--right {
  float: right;
}
.result-chooser-wrapper--right:last-child {
  margin-right: 0;
}
.option:hover {
  background-color: var(--grayish);
}
.search-info {
  color: var(--grayish);
  margin-bottom: 1rem;
}
.button-area {
  float: right;
}
.button--selected {
  border-bottom: 2px solid !important;
}
.download-form-wrapper {
  max-height: 0;
  overflow: hidden;
  height: 300px;
  max-height: 0px;
  opacity: 0;
  transition-duration: 400ms;
  transition-timing-function: ease-out;
  transition-property: max-height, opacity;
}
.download-form-wrapper--open {
  max-height:400px;
  transition-duration: 600ms;
  height: auto;
  opacity: 1;
}

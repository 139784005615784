.results-area a{
    text-decoration: none;
  }
  .result {
    display: block;
    position: relative;
    padding: 2rem;
    border-bottom: 1px solid #dee2e6;
    transition: 0.2s ease-out;
    color: #333;
  }
  .result-title-link:hover, .result-title-link:hover {
    color: var(--primary);
    text-decoration: underline;
  }
  .result-title {
    padding: 0.5rem 0;
    margin: 0;
    line-height: 1.5rem;
    font-weight: 600;
  }
  .result-index {
    position: absolute;
    top: 3px;
    left: 6px;
    color: var(--grayish);
    font-size: 0.95rem;
  }
  .result-snippets {
    font-size: 0.9rem;
  }

.MuiTableRow-hover {
  cursor: pointer;

}
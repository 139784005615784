.listblock--item-container {
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  width: 100%;
  line-height: 2rem;
  position: relative;
}

.listblock--item-key {
  display: inline-block;
  max-width: 80%;
  color: var(--grayish);
}

.listblock--item-value {
  color: var(--grayish);
}
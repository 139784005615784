.document-type-select {
    background: white;
    margin: 0;
    padding: 1rem;
    border: 1px solid #dee2e6;
    margin-bottom: -1px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    cursor: pointer;
    color: var(--primary);
    font-size: 0.9rem;
}
.document-type-select--active {
    color: black;
    background-color: #fafafa;
    border-bottom: 1px solid transparent;
}
.tags {
    margin: 1rem 0;
    font-size: 0.9rem;
  }
  .tags--results {
    margin-bottom: 0;
  }
  .tag {
    display: inline-block;
    color: var(--grayish);
    margin: 0 0.5rem 0 0;
  }
  .tag::after {
    content: ", ";
  } 
  .tag:last-child::after {
    content: "";
  }



.map-container {
    width: 500px;
    height: 300px;
}

#gardensMap {
    height: 750px;
    max-width: 50%;
    margin: 0 auto;
    z-index: 77;
}


/* general */

.section {
    margin: 0;
}
.section__inner {
    /*max-width: 1300px;*/
    width: 100%;
    margin: 0 auto;
    text-align: left;
    padding: 1rem;
}

/* special */

.section--header {
    background-color: white;
    padding: 0 0 4rem;
}
.section--header__inner {
    padding-bottom: 0;
}
.section--content {
    margin-bottom: 4rem;
    border-top: 1px solid #dee2e6;
}
.section--select-area {
    background-color: white;
}
.section--select-area__inner {
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
}
@media only screen and (min-width: 600px) {
    .section--document {
      padding-top: 2rem;
    }
  }
.prefilters-button-container {
    text-align: center;
    margin: 1rem auto;
}

.prefilters-form-container {
    box-sizing: border-box;
    height: 400px;
    max-height: 0px;
    opacity: 0;
    transition-duration: 500ms;
    transition-timing-function: ease-out;
    transition-property: max-height, opacity;
}
.prefilters-form-container--open {
    transition-duration: 800ms;
    max-height: 600px;
    height: auto;
    opacity: 1;
  }

.prefilters__form-control {
    width: 100%;
}

.prefilters-form .prefilters__form-control--50 {
    width: 50%;
    padding-right: 20px
}

.prefilters-form {
    margin-bottom: 4rem;
    padding: 2rem;
    overflow-x: hidden;
}

.prefilters__form-control .prefilters-form__input {
    width: 100%;
    margin-bottom: 1rem;
}

.prefilters-form .auto-chips {
    white-space: normal;
}

.prefilters-button-wrapper {
    text-align: center;
}

.prefilters-button {
    width: 200px;
    max-width: 100%;
    margin-bottom: 2rem;
}
  .filters-content {
    padding: 20px 20px 50px 20px;
  }
  .filters-load-button {
    width:50%;
  }
  .filters-form-group-label span {
    text-decoration: underline;
    font-size: 1.1em;
  }
  .filters-error-message {
    color:red;
    font-size: 0.8em;
  }
  .filters-grid-element {
    padding:20px;
  }

.form--measurements {
  min-width: 50%;
}